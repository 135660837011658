var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticStyle:{"height":"100%"}},[_c('Row',[_c('Col',{attrs:{"span":"6"}},[_c('Button',{staticClass:"back",on:{"click":_vm.goBack}},[_vm._v("返回")])],1)],1),_c('div',{staticClass:"tabelDiv"},[_c('Table',{attrs:{"columns":_vm.listColumns,"data":_vm.listData,"border":""},scopedSlots:_vm._u([{key:"purchase_unit_price",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","type":"tel","maxlength":"12","clearable":""},on:{"on-change":function($event){return _vm.changePurchasePrice(row.purchase_unit_price, index)}},model:{value:(row.purchase_unit_price),callback:function ($$v) {_vm.$set(row, "purchase_unit_price", $$v)},expression:"row.purchase_unit_price"}})]}},{key:"sale_unit_price",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","type":"tel","maxlength":"12","clearable":""},on:{"on-change":function($event){return _vm.changeSalePrice(row.sale_unit_price, index)}},model:{value:(row.sale_unit_price),callback:function ($$v) {_vm.$set(row, "sale_unit_price", $$v)},expression:"row.sale_unit_price"}})]}},{key:"bar_code",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","size":"small","type":"tel","maxlength":"50"},on:{"on-change":function($event){return _vm.changeTiaoma(row.bar_code, index)}},model:{value:(row.bar_code),callback:function ($$v) {_vm.$set(row, "bar_code", $$v)},expression:"row.bar_code"}})]}},{key:"batch_number",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","size":"small","type":"tel","maxlength":"50"},on:{"on-change":function($event){return _vm.changePihao(row.batch_number, index)}},model:{value:(row.batch_number),callback:function ($$v) {_vm.$set(row, "batch_number", $$v)},expression:"row.batch_number"}})]}},{key:"quantity",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"请输入","size":"small","type":"number"},on:{"on-change":function($event){return _vm.changeShuliang(row.quantity, index)}},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})]}},{key:"valid_period",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('DatePicker',{staticStyle:{"width":"140px"},attrs:{"type":"date","placeholder":"请选择","placement":"left-start","format":"yyyy-MM-dd"},on:{"on-change":function($event){return _vm.changeDate(row.valid_period, index)}},model:{value:(row.valid_period),callback:function ($$v) {_vm.$set(row, "valid_period", $$v)},expression:"row.valid_period"}})]}}])})],1),_c('Row',{staticClass:"btn-group"},[(_vm.type === 2 && _vm.edit === 1)?_c('Col',{attrs:{"span":"24"}},[_c('Button',{staticClass:"btn",attrs:{"disabled":_vm.disableB},on:{"click":_vm.outputStore}},[_vm._v("转出库单")]),_c('Button',{staticClass:"btn",attrs:{"disabled":_vm.disableA},on:{"click":_vm.transferStatement}},[_vm._v("转结算单")])],1):_vm._e(),(_vm.type === 3 && _vm.edit === 1)?_c('Col',{attrs:{"span":"24"}},[_c('Button',{staticClass:"btn",attrs:{"disabled":_vm.disableC},on:{"click":_vm.resaleOutboundOrder}},[_vm._v("转销售出库单")])],1):_vm._e(),(_vm.type === 4 && _vm.edit === 1)?_c('Col',{attrs:{"span":"24"}},[_c('Button',{staticClass:"btn",on:{"click":_vm.transferOrder}},[_vm._v("转单")])],1):_vm._e()],1),_c('Modal',{attrs:{"label-position":"left","width":"400","footer-hide":true,"closable":false,"class-name":"vertical-center-modal"},model:{value:(_vm.deleteModel),callback:function ($$v) {_vm.deleteModel=$$v},expression:"deleteModel"}},[_c('div',{staticClass:"reviewDiv"},[_c('p',[_vm._v("是否删除记录？")]),_c('div',[_c('span',{staticClass:"pageBtn finger btnSure",on:{"click":_vm.deleteAddItem}},[_vm._v("确定")]),_c('span',{staticClass:"pageBtn finger btnCancle",on:{"click":function($event){_vm.deleteModel = false}}},[_vm._v("取消")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }